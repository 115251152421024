import React from 'react'
import { Link } from 'react-router-dom'
const Navbar = () => {
  return (
    <>
<header id="header" className="header fixed-top">

<div className="topbar d-flex align-items-center">
  <div className="container d-flex justify-content-center justify-content-md-between">
    <div className="contact-info d-flex align-items-center">
      <i className="bi bi-envelope d-flex align-items-center"><Link to="mailto:contact@example.com">info@willowdecorist.com</Link></i>
      <i className="bi bi-phone d-flex align-items-center ms-4"><span>+923001234567</span></i>
    </div>
    <div className="languages d-none d-md-flex align-items-center">
     
    </div>
  </div>
</div>

<div className="branding d-flex align-items-cente">

  <div className="container position-relative d-flex align-items-center justify-content-between">
    <Link to="index.html" className="logo d-flex align-items-center me-auto me-xl-0">
     
      <h1 className="sitename">Willow Decorist</h1>
      
    </Link>

    <nav id="navmenu" className="navmenu">
      <ul>
        <li><Link to="/">Home<br/></Link></li>
        <li><Link to="/WillowDecorist_About">About</Link></li>
        <li><Link to="/WillowDecorist_Events">Events</Link></li>
        <li><Link to="/WillowDecorist_Contact">Contact Us</Link></li>
      </ul>
      <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
    </nav>

    <Link className="btn-book-a-table d-none d-xl-block" to="#book-a-table">Book a Event</Link>

  </div>

</div>

</header>
</>
  )
}

export default Navbar