import React from 'react'
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css"
import Navbar from './Header/Navbar'
import Home from "./Pages/Home.jsx"
import Footer from './Header/Footer.jsx';
import Whatsapp from "./Pages/Sociallinks/Whatsapp/Whatsapp.jsx"
import Sociallinkk from "./Pages/Sociallinks/Socialicons/Social.jsx"
import Event from "./Pages/Events/Event.jsx"
import Contactus from './Pages/ContactUS/Contactus.jsx';
import Aboutus from './Pages/Aboutus.jsx';

const App = () => {
  return (
  <>
<Routes> 
        <Route path="/" element={<Home />}/>
     
        <Route path="/WillowDecorist_Events" element={<Event/>}/>
        <Route path="/WillowDecorist_Contact" element={<Contactus/>}/>
        <Route path="/WillowDecorist_About" element={<Aboutus/>}/>

        <Route path="*" element={<Home/>} /> 
      </Routes>



  <Whatsapp/>
  <Sociallinkk/>
  <Navbar/>
  <Footer/>
  </>
  )
}

export default App