import React from 'react'

const Event = () => {
  return (
   <>
     <div class="page-title position-relative headerimg1" data-aos="fade">
      <div class="container position-relative">
        <h1>Events<br/></h1>
        <nav class="breadcrumbs">
        </nav>
      </div>
    </div>
   <section id="why-us" class="why-us section">

   <div class="container">

<div class="row gy-4">

  <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
    <div class="card-item">
      <span>Weddings</span>
      <h4><a href="" class="stretched-link">More Information</a></h4>
      <p>"Transforming your wedding dreams into a flawlessly orchestrated reality."</p>
    </div>
  </div>
  <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
    <div class="card-item">
      <span>Business Events</span>
      <h4><a href="" class="stretched-link">More Information</a></h4>
      <p>"Crafting seamless business events that elevate your brand and inspire success."</p>
    </div>
  </div>

  <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
    <div class="card-item">
      <span>Sporting Events</span>
      <h4><a href="" class="stretched-link">More Information</a></h4>
      <p>"Delivering unforgettable sporting events that captivate fans and celebrate athletic excellence"</p>
    </div>
  </div>

</div>
<div class="row gy-4">

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
<div class="card-item">
<span>Social Events</span>
<h4><a href="" class="stretched-link">More Information</a></h4>
<p>"Curating memorable social events that bring people together and create lasting connections."</p>
</div>
</div>
<div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
<div class="card-item">
<span>Birthday parties</span>
<h4><a href="" class="stretched-link">More Information</a></h4>
<p>"Turning birthday celebrations into unforgettable moments of joy and laughter."</p>
</div>
</div>

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
<div class="card-item">
<span>Awards ceremonies</span>
<h4><a href="" class="stretched-link">More Information</a></h4>
<p>
"Elevating achievements with flawlessly executed awards ceremonies that honor excellence and inspire."</p>
</div>
</div>

</div>
<div class="row gy-4">

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
<div class="card-item">
<span>Music Performance</span>
<h4><a href="" class="stretched-link">More Information</a></h4>
<p>"Producing unforgettable music performances that resonate with audiences and set the stage for extraordinary moments."</p>
</div>
</div>
<div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
<div class="card-item">
<span>Exhibitions</span>
<h4><a href="" class="stretched-link">More Information</a></h4>
<p>
"Showcasing innovation and creativity with exhibitions that captivate and engage audiences."</p>
</div>
</div>

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
<div class="card-item">
<span>Seminars</span>
<h4><a href="" class="stretched-link">More Information</a></h4>
<p>"Delivering impactful seminars that educate, inspire, and drive meaningful dialogue."</p>
</div>
</div>

</div>

</div>

</section>
   </>
  )
}

export default Event