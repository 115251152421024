import React from 'react'
import {Link} from "react-router-dom"
const Footer = () => {
  return (
  <>
   <footer id="footer" class="footer">

<div class="container footer-top">
  <div class="row gy-4">
    <div class="col-lg-4 col-md-6 footer-about">
      <a href="index.html" class="logo d-flex align-items-center">
        <span class="sitename">Willow Decorist</span>
      </a>
      <div class="footer-contact pt-3">
        <p>A108 Adam Street</p>
        <p>New York, NY 535022</p>
        <p class="mt-3"><strong>Phone:</strong> <span>+1 5589 55488 55</span></p>
        <p><strong>Email:</strong> <span>info@willowdecorist.com</span></p>
      </div>
      <div class="social-links d-flex mt-4">
        <a href=""><i class="bi bi-twitter-x"></i></a>
        <a href=""><i class="bi bi-facebook"></i></a>
        <a href=""><i class="bi bi-instagram"></i></a>
        <a href=""><i class="bi bi-linkedin"></i></a>
      </div>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Useful Links</h4>
      <ul>
        <li><Link to="https://willowdecorist.com">Home</Link></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Event</a></li>
        <li><a href="#">Contact Us</a></li>
        <li><a href="#">Book Events</a></li>
      </ul>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Our Services</h4>
      <ul>
        <li><a href="#">Weddings</a></li>
        <li><a href="#">Business Events</a></li>
        <li><a href="#">Sporting Events</a></li>
        <li><a href="#">Social Events</a></li>
        <li><a href="#">Birthday parties</a></li>
      </ul>
    </div>

    <div class="col-lg-4 col-md-12 footer-newsletter">
      <h4>Our Newsletter</h4>
      <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
     
    </div>

  </div>
</div>

<div class="container copyright text-center mt-4">
  <p>© <span>Copyright</span> <strong class="px-1 sitename">Willow Decorist</strong> <span>All Rights Reserved</span></p>
  <div class="credits">

    Designed by <a href="https://divcodex.com">DivCodeX</a>
  </div>
</div>

</footer>
  
  </>
  )
}

export default Footer