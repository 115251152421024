import React from 'react'
import img1 from "../img/gallery/1.jpg"
import img2 from "../img/gallery/2.jpg"
import img3 from "../img/gallery/3.jpg"
import img4 from "../img/gallery/4.jpg"
import img5 from "../img/gallery/5.jpg"
import img6 from "../img/gallery/6.jpg"
import img0 from "../img/gallery/7.jpg"
import img7 from "../img/gallery/8.jpg"
import img8 from "../img/gallery/9.jpg"
import img9 from "../img/gallery/10.jpg"
import img10 from "../img/gallery/11.jpg"
import img11 from "../img/gallery/12.jpg"
import img12 from "../img/gallery/13.jpg"
import img13 from "../img/gallery/14.jpg"
import img14 from "../img/gallery/15.jpg"
import img15 from "../img/gallery/16.jpg"

const Galleryhome = () => {
  return (
   <>
    <section id="gallery" class="gallery section">
<div class="container section-title" data-aos="fade-up">
  <h2>Gallery</h2>
  <p>Some photos from Willow Decorist Events</p>
</div>

<div class="container-fluid" data-aos="fade-up" data-aos-delay="100">

  <div class="row g-0">

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-1.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img1} alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-2.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img2} alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-3.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img3}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-4.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img4}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-5.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img5}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-6.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img6}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>
    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-7.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img0}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-8.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img7} alt="" class="img-fluid"/>
        </a>
      </div>
    </div>
    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-1.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img9} alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-2.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img10} alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-3.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img11}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-4.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img12}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-5.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img13}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-6.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img14}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>
    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-7.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img15}  alt="" class="img-fluid"/>
        </a>
      </div>
    </div>

    <div class="col-lg-3 col-md-4">
      <div class="gallery-item">
        <a href="assets/img/gallery/gallery-8.jpg" class="glightbox" data-gallery="images-gallery">
          <img src={img8} alt="" class="img-fluid"/>
        </a>
      </div>
    </div>
  </div>

</div>

</section>
   
   </>
  )
}

export default Galleryhome