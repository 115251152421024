import React from 'react'
import img2 from "./Aboutus.jpg"
const Aboutus = () => {
  return (
   <>
   
   <section id="about" class="about section">

<div class="container" data-aos="fade-up" data-aos-delay="100">

  <div class="row gy-4 mt-5">
    <div class="col-lg-6 order-1 order-lg-2">
      <img src={img2} class="img-fluid about-img" alt=""/>
    </div>
    <div class="col-lg-6 order-2 order-lg-1 content">
      <h3>"Transforming spaces into timeless sanctuaries with elegance and grace."</h3>
      <p class="fst-italic">
      At Willow Decorist, we believe that your home should be a reflection of your unique style and personality. Our mission is to transform your living spaces into beautifully designed, harmonious environments where you feel truly at home.
      </p>
      <ul>
        <li><i class="bi bi-check2-all"></i> <span>Interior Design Consultation: Personalized design strategies tailored to your preferences and needs.</span></li>
        <li><i class="bi bi-check2-all"></i> <span>Space Planning: Thoughtful layouts that maximize functionality and flow.</span></li>
        <li><i class="bi bi-check2-all"></i> <span>Custom Furnishings & Decor: Unique pieces and design elements to elevate your space.</span></li>
        <li><i class="bi bi-check2-all"></i> <span>Color & Material Selection: Expert guidance on color palettes, textures, and finishes.</span></li>
        <li><i class="bi bi-check2-all"></i> <span>Project Management: Seamless coordination and execution to bring your vision to life.</span></li>

      </ul>
      <p>
      Inspired by the elegance and tranquility of nature, Willow Decorist brings a touch of serenity and sophistication to every project.
      </p>
    </div>
  </div>

</div>

</section>

<section id="why-us" class="why-us section">

 
      <div class="container section-title" data-aos="fade-up">
        <h2>WHY US</h2>
        <p>Why Choose Willow Decorist</p>
      </div>

      <div class="container">

        <div class="row gy-4">

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="card-item">
              <span>Weddings</span>
              <h4><a href="" class="stretched-link">More Information</a></h4>
              <p>"Transforming your wedding dreams into a flawlessly orchestrated reality."</p>
            </div>
          </div>
          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="card-item">
              <span>Business Events</span>
              <h4><a href="" class="stretched-link">More Information</a></h4>
              <p>"Crafting seamless business events that elevate your brand and inspire success."</p>
            </div>
          </div>

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
            <div class="card-item">
              <span>Sporting Events</span>
              <h4><a href="" class="stretched-link">More Information</a></h4>
              <p>"Delivering unforgettable sporting events that captivate fans and celebrate athletic excellence"</p>
            </div>
          </div>

        </div>
        <div class="row gy-4">

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
  <div class="card-item">
    <span>Social Events</span>
    <h4><a href="" class="stretched-link">More Information</a></h4>
    <p>"Curating memorable social events that bring people together and create lasting connections."</p>
  </div>
</div>
<div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
  <div class="card-item">
    <span>Birthday parties</span>
    <h4><a href="" class="stretched-link">More Information</a></h4>
    <p>"Turning birthday celebrations into unforgettable moments of joy and laughter."</p>
  </div>
</div>

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
  <div class="card-item">
    <span>Awards ceremonies</span>
    <h4><a href="" class="stretched-link">More Information</a></h4>
    <p>
    "Elevating achievements with flawlessly executed awards ceremonies that honor excellence and inspire."</p>
  </div>
</div>

</div>
<div class="row gy-4">

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
  <div class="card-item">
    <span>Music Performance</span>
    <h4><a href="" class="stretched-link">More Information</a></h4>
    <p>"Producing unforgettable music performances that resonate with audiences and set the stage for extraordinary moments."</p>
  </div>
</div>
<div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
  <div class="card-item">
    <span>Exhibitions</span>
    <h4><a href="" class="stretched-link">More Information</a></h4>
    <p>
    "Showcasing innovation and creativity with exhibitions that captivate and engage audiences."</p>
  </div>
</div>

<div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
  <div class="card-item">
    <span>Seminars</span>
    <h4><a href="" class="stretched-link">More Information</a></h4>
    <p>"Delivering impactful seminars that educate, inspire, and drive meaningful dialogue."</p>
  </div>
</div>

</div>

      </div>

    </section>
</>

  )
}

export default Aboutus