import React from "react";
import "./Whatsapp.css"
import {Link} from "react-router-dom";
const Whatsapp = ()=>{
return(
<>  
<Link  className="whats-app" to="https://api.whatsapp.com/send?phone=+923068459341text=Hello%20Willow%20Decorist" target="_blank">
    <i className="bi bi-whatsapp my-float"></i>
</Link>
</>

);

}
export default Whatsapp;